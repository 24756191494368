<template>
  <div class="card">
    <!-- <h1 class="tw-text-xl tw-font-bold tw-mb-8">Edit Employee</h1> -->
    <div>
      <BarberForm @submit="updateBarber" :initial-data="initData" :title="'Edit Pegawai'" is_create="true" />
    </div>
  </div>
</template>

<script setup>
import { useRoute, useRouter } from "vue-router";
import { onBeforeMount, ref } from "vue";

import BarberForm from "./Form";
import { useBarberForm } from "@/composables/barber";

const initData = ref({});
const { update, getDetail } = useBarberForm();
const router = useRouter();
const route = useRoute();

const updateBarber = async (data) => {
  await update(route.params.id, data);
  await router.push({ name: "barbers" });
};

onBeforeMount(async () => {
  initData.value = await getDetail(route.params.id);
});
</script>

<style scoped></style>
